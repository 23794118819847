<template>
  <div class="main-body">
    <a-form ref="formRef" :model="formState" v-bind="formItemLayout" @finishFailed="onFinishFailed" @finish="onFinish">
      <a-tabs>
        <a-tab-pane key="1" tab="基础信息">
          <a-form-item label="标题" name="title" :rules="[{ required: true, message: '请输入标题!' }]">
            <a-input v-model:value="formState.title" placeholder="请输入标题" />
          </a-form-item>
          <a-form-item label="封面" name="thumb" extra="请上传280*140px的图片">
            <div>
              <a-input v-model:value="formState.thumb" placeholder="输入封面图片地址或者上传封面" class="medium-input" />
              <a-form-item-rest>
                <CsImage v-model:imgOne="formState.thumb" :style="'button'" :btntxt="'上传封面'" />
              </a-form-item-rest>
            </div>
            <a-image v-if="formState.thumb != ''" :width="200" :src="formState.thumb" style="margin-top:10px" />
          </a-form-item>
          <a-form-item label="属性">
            <a-checkbox-group v-model:value="formState.flag">
              <a-checkbox value="1" name="flag">置顶</a-checkbox>
              <a-checkbox value="2" name="flag">推荐</a-checkbox>
              <a-checkbox value="3" name="flag">热门</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item label="描述" name="description" >
            <a-textarea v-model:value="formState.description" placeholder="请输入描述" />
          </a-form-item>
          <a-form-item label="属性" name="content_type">
            <a-radio-group v-model:value="formState.content_type">
              <a-radio :value="item.code" v-for="(item, index) in contentModel" :key="index" :disabled="true">
                {{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="formState.content_type == 'document'" label="内容" name="content"
            :rules="[{ required: true, message: '请输入内容!' }]">
<!--            <a-button class="margin-bottom16">导入文档</a-button>-->
            <!-- <a-textarea v-model:value="formState.content" placeholder="请输入内容" /> -->
            <BasicEditor v-model="formState.content"></BasicEditor>
          </a-form-item>
          <a-form-item v-else-if="formState.content_type == 'download'" label="内容" name="fileList"
            :rules="[{ required: true, message: '请上传附件!' }]">
            <div style="width:400px">
              <a-upload v-model:fileList="formState.fileList" class="margin-bottom16" name="iFile" :multiple="true"
                :showUploadList="true" :action="uploadAction" @change="uploadHandleChange">
                <a-button>
                  <CloudUploadOutlined />
                  上传附件
                </a-button>
              </a-upload>
            </div>
          </a-form-item>
          <a-form-item v-else-if="formState.content_type == 'photos'" label="内容" name="imageList"
            :rules="[{ required: true, message: '请上传图集!' }]">
            <div class="muti-image-body">
              <div class="muti-image-item" v-for="(item, index) in formState.imageList" :key="index">
                <a-row>
                  <a-col :span="4">
                    <a-form-item-rest>
                      <CsImage v-model:imgOne="item.imgUrl" />
                    </a-form-item-rest>
                  </a-col>
                  <a-col :span="20">
                    <a-form-item-rest>
                      <a-input class="muti-image-item-title" v-model:value="item.imgTitle" placeholder="请输入标题" />
                    </a-form-item-rest>
                    <a-form-item-rest>
                      <a-input v-model:value="item.imgLink" placeholder="请输入跳转地址" />
                    </a-form-item-rest>
                  </a-col>
                </a-row>
                <CloseCircleOutlined @click="removeImageItem(item)" class="delete-image-item" />
              </div>
              <a-button type="dashed" block @click="addImageItem">
                <PlusOutlined />
                添加图片
              </a-button>
            </div>
            <!-- <mavon-editor :subfield="false" v-model:value="formState.description" /> -->
          </a-form-item>
          <a-form-item label="状态" name="status">
            <a-radio-group v-model:value="formState.status">
              <a-radio value="1">正常</a-radio>
              <a-radio value="0">待审核</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="扩展信息">


          <a-form-item v-for="(item, index) in formState.extraFields" :key="index" :label="item.field_title">
            <template v-if="item.field_type == 'text'">
              <a-input v-model:value="formState.extraFields[index].value" class="medium-large-input" />
            </template>
            <template v-else-if="item.field_type == 'short_text'">
              <a-input v-model:value="formState.extraFields[index].value" class="medium-input" />
            </template>
            <template v-else-if="item.field_type == 'long_text'">
              <a-input v-model:value="formState.extraFields[index].value" />
            </template>
            <template v-else-if="item.field_type == 'number'">
              <a-input-number v-model:value="formState.extraFields[index].value" :min="0" />
            </template>
            <template v-else-if="item.field_type == 'textarea'">
              <a-textarea v-model:value="formState.extraFields[index].value" :rows="4"
                :placeholder="'请输入' + item.field_title" />
            </template>
          </a-form-item>

          <a-form-item label="来源">
            <a-row>
              <a-col :span="12">
                <a-form-item no-style name="source">
                  <a-input v-model:value="formState.source" class="medium-input" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="地址" name="source_url">
                  <a-input v-model:value="formState.source_url" class="medium-input" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="作者">
            <a-row>
              <a-col :span="12">
                <a-form-item no-style name="author">
                  <a-input v-model:value="formState.author" class="medium-input" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="编辑" name="editor">
                  <a-input v-model:value="formState.editor" class="medium-input" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="发布时间">
            <a-row>
              <a-col :span="12">
                <a-form-item no-style name="push_time">
                  <a-date-picker v-model:value="formState.push_time" format="YYYY-MM-DD"/>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="SEO关键词" name="keyword">
            <a-input v-model:value="formState.keyword" placeholder="请输入SEO关键词" />
          </a-form-item>
          <a-form-item label="SEO描述" name="seo_desc">
            <a-textarea v-model:value="formState.seo_desc" placeholder="请输入SEO描述" />
          </a-form-item>
          <a-form-item label="排序" name="sort">
            <a-input-number v-model:value="formState.sort" class="medium-input"></a-input-number>
          </a-form-item>
          <a-form-item label="附件" name="extraList">
            <div style="width:400px">
              <a-upload v-model:fileList="formState.extraList" class="margin-bottom16" name="iFile" :multiple="true" :headers="headers"
                :showUploadList="true" :action="uploadAction" @change="uploadHandleChange">
                <a-button type="dashed" block class="medium-input">
                  <PlusOutlined />
                  添加附件
                </a-button>
              </a-upload>
            </div>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-item :wrapper-col="{ span: 12, offset: 2 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted
} from "vue";
import {
  message,
  Modal
} from "ant-design-vue";
import CsImage from "@/components/UpImage";
import * as Api from "@/addons/cms/api";
import "./style/publish.less";
import {
  CloseCircleOutlined,
  PlusOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons-vue";
// import { message } from "ant-design-vue";
import {
  useRoute,
  useRouter
} from "vue-router";
import BasicEditor from "@/components/Editor";
import storage from "store";
import dayjs from 'dayjs';
export default defineComponent({
  components: {
    CloseCircleOutlined,
    PlusOutlined,
    CsImage,
    CloudUploadOutlined,
    BasicEditor,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const article_id = route.query.id;

    const formRef = ref();
    //表单数据
    const formState = reactive({
      article_id: article_id,
      category_id: 0,
      title: "",
      thumb: "",
      description: "",
      content_type: "document",
      content: "",
      fileList: [],
      imageList: [],
      status: "1",
      flag: [],
      // 扩展信息
      source: "",
      source_url: "",
      author: "",
      editor: "",
      push_time:undefined,
      keyword: "",
      seo_desc: "",
      sort: 100,
      extraFields: [],
      extraList: [],
    });

    const addImageItem = () => {
      formState.imageList.push({
        imgUrl: "",
        imgTitle: "",
        imgLink: "",
      });
    };
    const headers = ref({
      "Access-Token": storage.get("ACCESS_TOKEN"),
    });
    const removeImageItem = (item) => {
      if (formState.imageList.length > 1) {
        let index = formState.imageList.indexOf(item);

        if (index !== -1) {
          formState.imageList.splice(index, 1);
        }
        message.success("删除成功");
      } else {
        message.warning("至少保留1项！");
      }
    };

    const contentModel = ref([{
      name: "文档",
      code: "document",
    },
    {
      name: "下载",
      code: "download",
    },
    {
      name: "图集",
      code: "photos",
    },
    ]);

    const onFinish = () => {
      formRef.value
        .validate()
        .then(() => {
          Api.articleUpdate(formState).then((res) => {
            if (res.status === 200) {
              Modal.success({
                title: "提示",
                content: res.message,
                okText: "返回列表",
                onOk() {
                  router.push({
                    path: "/cms/content/list",
                    query: {
                      category_id: formState.category_id,
                    },
                  });
                },
              });
            } else {
              message.error(res.message);
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    //上传文件
    const uploadAction = Api.upload;

    const uploadHandleChange = (info) => {
      if (info.file.status === "done") {
        if (info.file.response.status === 200) {
          message.success(`${info.file.name} 上传成功`);
          console.log(formState.fileList);
        } else {
          message.error(info.file.response.message);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    const onLoadInit = () => {
      Api.getArticleDetail({
        article_id: article_id,
      }).then((res) => {
        if (res.status == 200) {
          let info = res.data;
          formState.category_id = info.category_id;
          formState.title = info.title;
          formState.thumb = info.thumb;
          formState.description = info.description;
          formState.content_type = info.content_type;
          formState.content = info.content;
          formState.fileList = info.fileList;
          formState.imageList = info.imageList;
          formState.status = info.status.toString();
          formState.source = info.source;
          formState.source_url = info.source_url;
          formState.author = info.author;
          formState.push_time = dayjs(info.push_time);
          formState.editor = info.editor;
          formState.keyword = info.keyword;
          formState.seo_desc = info.seo_desc;
          formState.sort = info.sort;
          // formState.extraFields = info.extra_fields;
          formState.extraList = info.extra_files;
          formState.flag = info.flag;

          // 处理额外字段遍历-如果分类额外字段有修改
          Api.categoryDetail({
            category_id: info.category_id,
          }).then((res) => {
            if (res.status === 200) {
              if (res.data.fields != null) {
                formState.extraFields = res.data.fields;
                formState.extraFields.forEach((item, index) => {
                  info.extra_fields.forEach((it) => {
                    if (item.field_name == it.field_name) {
                      formState.extraFields[index].value = it.value;
                    }
                  });
                });
              }
            }
          });
        } else {
          message.error(res.message);
        }
      });
    };

    onMounted(() => {
      // 获取文档数据
      onLoadInit();
    });

    return {
      headers,
      formRef,
      formState,
      contentModel,
      onFinish,
      onFinishFailed,
      addImageItem,
      removeImageItem,
      formItemLayout: {
        labelCol: {
          span: 2,
        },
        wrapperCol: {
          span: 16,
        },
      },
      uploadAction,
      uploadHandleChange,
    };
  },
});
</script>